import React, { Component } from "react";

class ContactForm extends Component {
  render() {
    return (
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <div className="form-header">Let's stay in touch!</div>
        <p>
          <label>Your Name:</label>
          <input type="text" name="name" required="true" />
        </p>
        <p>
          <label>Your Email:</label>
          <input type="email" name="email" required="true" />
        </p>
        <p>
          <label>Message:</label>
          <textarea name="message" />
        </p>
        <p>
          <button className="submit" type="submit">
            Submit
          </button>
        </p>
      </form>
    );
  }
}

export default ContactForm;
