import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "../src/ContactForm";
import {
  faLinkedinIn,
  faTwitter,
  faGithubAlt,
  faFacebook
} from "@fortawesome/free-brands-svg-icons";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Jay Hoots</h1>
          <h4>Web Dev / Musician</h4>
          <div className="social">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/hootsieroll"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://facebook.com/hootsieroll/"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/hootsieroll"
            >
              <FontAwesomeIcon icon={faGithubAlt} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/jay-hoots/"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </div>
          <ContactForm />
        </header>
      </div>
    );
  }
}

export default App;
